<template>
  <div class="theme--light">
    <div class="tw-px-4 tw-py-4 tw-flex tw-items-center tw-justify-between"><h3 class="tw-mb-0 font-weight-regular">SIP Trunk CDRs - {{ serviceTitle }}</h3><div class="spacer"></div></div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Month</div>
        <div class="dx-field-value">
          <DxSelectBox
              :data-source="dates"
              display-expr="ID"
              value-expr="ID"
              @option-changed="setDate"
          />
        </div>
      </div>
    </div>

  <DxDataGrid
      :columns="columns"
      :show-borders="true"
      :data-source="items"
      @exporting="onExporting"
  >
    <DxExport
        :enabled="true"
    />
    <DxSummary>
       <DxTotalItem
          column="price_rated"
          summary-type="sum"
          value-format="currency" />
      <DxTotalItem
          column="duration"
          summary-type="sum"
          :customize-text="customizeText"
          value-format="decimal" />
    </DxSummary>
  </DxDataGrid>
  </div>
</template>

<script>
import 'devextreme/dist/css/dx.light.css';
import Http from '@/utils/httpClient';
import { DxSelectBox } from 'devextreme-vue/select-box';
import {
  DxDataGrid,
  DxSummary,
  DxExport,
  DxTotalItem
} from 'devextreme-vue/data-grid';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

export default {
    components: {
      DxSelectBox,
      DxDataGrid,
      DxSummary,
      DxTotalItem,
      DxExport
    },
    data() {
        return {
            items: [],
            columns: ['call_start_time', 'src_username', 'dst_username', 'price_rated', 'duration'],
            serviceTitle: '',
            cdrDate: '2021-10-01',
            dates: [
              { ID: '2021-10-01' },
              { ID: '2021-09-01' },
            ]
        };
    },
    watch: {
        $route() {
            this.init();
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        setDate(e) {
          if (e.name === 'selectedItem') {
            console.log('setDate', e, e.value.ID);
            this.cdrDate = e.value.ID;
            this.loadCDR(this.cdrDate);
          }
        },
        onExporting(e) {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Main sheet');
          exportDataGrid({
            component: e.component,
            worksheet,
            customizeCell(options) {
              const excelCell = options;
              excelCell.font = { name: 'Arial', size: 12 };
              excelCell.alignment = { horizontal: 'left' };
            }
          }).then(() => {
            workbook.xlsx.writeBuffer()
                .then((buffer) => {
                  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
          });
          e.cancel = true;
        },
        customizeText(e) {
          const mins = Math.floor((e.value / 60) * 100) / 100;
          return `Total Minutes ${mins}`;
        },
        loadCDR(startDate) {
          Http.get(`cdr/${this.$route.params.id}/${startDate}`)
              .then(({ data }) => {
                console.log('data', data);
                this.items = data.cdrs;
                this.serviceTitle = data.serviceTitle;
                this.$root.$loading.close();
              })
              .catch(() => this.$root.$loading.close());
        },
        init() {
            this.$root.$loading.open({ text: 'Fetching CDR table' });
            //const date = new Date();
            //const [month, year] = [date.getMonth(), date.getFullYear()];
            //let startDate = '';
            //const startDate = [year, month, '01'].join('-');
            //const startDate = '2021-09-01';
            const startDate = this.cdrDate;
            this.loadCDR(startDate);
        }
    }
};
</script>
<style>
.theme--light {
  background-color:white;
}
</style>
